import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { StepFormContainer, StepText, StepTitle } from '@shared/ui/sidebars/shared';
import Input from '@shared/ui/inputs/Input';
import Button from '@shared/ui/buttons/Button';
import QuantityInput from '@shared/ui/inputs/QuantityInput';
import { PortfolioType } from '@entities/portfolio/model/Portfolio';
import IconText from '@shared/ui/icons/IconText';
import { ReactComponent as TriangleIcon } from '@icons/wolfkit-light/alert-triangle-light.svg';
import Icon from '@shared/ui/icons/Icon';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import WalletSelect from '@shared/ui/inputs/WalletSelect';
import { PortfolioCard } from '@entities/portfolio';
import { getCurrentDate } from '@shared/lib/date-time';
import { getProfileNameInitials } from '@shared/lib/profile';
import usePortfolioCreate from '../usePortfolioCreate';
import { portfolioSettingsValidationSchema } from './validation';
const MonthlyFeeIncDecStep = 5;
const InputContainer = styled.div(props => ({
    width: '100%',
    marginBottom: props.theme.spacing_sizes.l,
}));
const SelectContainer = styled(InputContainer, { shouldForwardProp: propName => propName !== 'hasError' })(props => ({
    marginBottom: props.hasError ? props.theme.spacing_sizes.xs * 0.5 :
        props.theme.spacing_sizes.s * 5,
}));
const MonthlyFeeContainer = styled(InputContainer)(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 6.75,
    maxWidth: 168,
}));
const IconTextStyled = styled(IconText)(props => ({
    alignSelf: 'center',
    '& .MuiTypography-root': {
        fontSize: 12,
        color: props.theme.palette.warning.main,
    },
}));
const PortfolioContainer = styled(InputContainer)(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 1.25,
}));
const PortfolioSettings = ({ handleClose }) => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const { goToNextStep, userId, currentUserProfile, sidebarFormValues, displayBackBtn, goToPrevStep, wallets, exchanges, isCreatingPortfolio, } = usePortfolioCreate({ handleClose });
    const theme = useTheme();
    const { handleSubmit, control, watch, formState: { errors }, setValue, trigger, } = useForm({
        defaultValues: sidebarFormValues,
        resolver: yupResolver(portfolioSettingsValidationSchema(t, 
        // TODO-FIX: would be modified for multiple exchanges
        exchanges)),
    });
    const monthlyFee = watch('monthlyFee');
    const portfolioName = watch('portfolioName');
    const walletId = watch('walletId');
    const hasErrors = (errors.monthlyFee &&
        errors.monthlyFee.message && ((_b = (_a = errors.monthlyFee) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.length) > 0) ||
        (errors.portfolioName &&
            errors.portfolioName.message && ((_c = errors.portfolioName.message) === null || _c === void 0 ? void 0 : _c.length) > 0) ||
        (errors.walletId &&
            errors.walletId.message && ((_e = (_d = errors.walletId) === null || _d === void 0 ? void 0 : _d.message) === null || _e === void 0 ? void 0 : _e.length) > 0);
    const walletError = errors.walletId &&
        errors.walletId.message ? errors.walletId.message : undefined;
    const portfolio = useMemo(() => {
        var _a;
        return ({
            id: '123',
            userId,
            portfolioName: (portfolioName === null || portfolioName === void 0 ? void 0 : portfolioName.length) ? portfolioName : 'Portfolio Name',
            walletId: walletId || '',
            profileId: (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id) || '',
            chat_activity: 0,
            copiers_count: 174,
            favorited_count: 0,
            followers: {
                count: 174,
                average_followers_monthly_return: 70.29,
                average_followers_monthly_return_first: 84.9,
                average_followers_monthly_return_last: 64.5,
                preview_followers: [],
            },
            subscriptionPrice: monthlyFee,
            graph_data: [],
            has_error: false,
            is_archived: false,
            logo: ((_a = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.profilePicture) === null || _a === void 0 ? void 0 : _a.thumbnail) ||
                getProfileNameInitials((currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.displayName) || ''),
            min_balance: 35,
            min_balance_currency: '$',
            minimum_balance_type: '1_to_1',
            visibility: 'public',
            viewed: 0,
            past_month_difference: 83.75,
            past_ninety_days_difference: 153.89,
            subscriptionPriceCurrency: '$',
            overall_difference: 153.89,
            profile: Object.assign({}, (currentUserProfile || {})),
            wallet: {
                type: 'SPOT',
                assets: [],
                externalFinanceAccountId: '2',
                id: '2',
                totalAssetsUsd: '0',
                totalAssetsUsdt: '0',
            },
            type: PortfolioType.Spot,
            referral_asset: 'usdt',
            past_week_difference: 4,
            past_month_start_date: 'Jan 4, 2021',
            past_month_end_date: 'Dec 3, 2021',
            whole_period_days_count: 90,
            shared_count: 5,
            traders_balance: 0,
            traders_balance_currency: '$',
            assessmentPassed: false,
            createdAt: `${getCurrentDate()}`,
        });
    }, [monthlyFee, portfolioName, currentUserProfile, userId, walletId]);
    const handleWalletSelect = (_ev, itemId) => {
        setValue('walletId', itemId);
        trigger('walletId');
    };
    const onSubmit = (values) => {
        goToNextStep(values);
    };
    return (_jsx(SidebarInner, { cancelBtnProps: { onClick: handleClose }, title: (displayBackBtn ? (_jsx(BackLinkButton, { onClick: goToPrevStep, disabled: isCreatingPortfolio, children: t('portfolio.create_portfolio.back_btn', { ns: 'common' }) })) : undefined), displayCancelBtn: true, children: _jsx(StepFormContainer, { onSubmit: handleSubmit(onSubmit), title: (_jsx(StepTitle, { children: t('portfolio.create_portfolio.steps.portfolio_settings.title', { ns: 'common' }) })), content: (_jsxs(_Fragment, { children: [_jsx(StepText, { text: t('portfolio.create_portfolio.steps.portfolio_settings.step_text', { ns: 'common' }), stepNumber: t('portfolio.create_portfolio.steps.portfolio_settings.step_number', { ns: 'common' }) }), _jsx(SelectContainer, { hasError: Boolean(walletError), children: _jsx(WalletSelect, { label: t('portfolio.create_portfolio.steps.portfolio_settings.parent_wallet.label', { ns: 'common' }), items: wallets.map(({ id, totalAssetsUsd, type, exchange, }) => ({
                                key: exchange.id,
                                items: [{
                                        walletType: type,
                                        id,
                                        amount: parseFloat(totalAssetsUsd),
                                        exchangeType: exchange.type,
                                        walletName: exchange.properties.connectionName,
                                    }],
                            })), value: walletId || '', onChange: handleWalletSelect, notification: walletError, notificationStatus: (walletError === null || walletError === void 0 ? void 0 : walletError.length) ? 'error' : undefined }) }), _jsx(InputContainer, { children: _jsx(Controller, { name: 'portfolioName', control: control, render: ({ field, fieldState }) => {
                                var _a;
                                return (_jsx(Input, Object.assign({}, field, { label: t('portfolio.create_portfolio.steps.portfolio_settings.portfolio_name.label', { ns: 'common' }), notificationStatus: ((_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message) ? 'error' : undefined, maxCount: 30, required: true })));
                            } }) }), _jsx(MonthlyFeeContainer, { children: _jsx(Controller, { name: 'monthlyFee', control: control, render: ({ field }) => (_jsx(QuantityInput, { label: t('portfolio.create_portfolio.steps.portfolio_settings.monthly_fee.label', { ns: 'common' }), size: 'small', incDecStep: MonthlyFeeIncDecStep, value: field.value, onChange: field.onChange, roundToMultiplesOfStep: true, min: 0, max: 500, fullWidth: true })) }) }), _jsx(PortfolioContainer, { children: _jsx(PortfolioCard, { viewType: 'preview-create', displayState: 'default', portfolio: portfolio, showActionsBtn: false, customBadgeText: t('portfolio.create_portfolio.steps.portfolio_settings.preview_label', { ns: 'common' }), badgeStyles: {
                                height: 30,
                                top: '-30px',
                                padding: '5px 20px',
                                '&:after': {
                                    borderWidth: '0 20px 25px 0',
                                },
                            }, selected: true }) }), _jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { IconComponent: TriangleIcon, color: theme.palette.warning.main, size: 16 })), text: t('portfolio.create_portfolio.steps.portfolio_settings.hint_text', { ns: 'common' }) })] })), contentAlign: 'start', buttons: (_jsx(Button, { type: 'submit', size: 'large', disabled: Boolean(hasErrors) || isCreatingPortfolio, isLoading: isCreatingPortfolio, fullWidth: true, children: t('portfolio.create_portfolio.steps.portfolio_settings.continue_btn', { ns: 'common' }) })), containerPaddingTop: theme.spacing_sizes.xs * 2 }) }));
};
export default PortfolioSettings;
